@import "base/utils/flexbox";

// statics
$FONT_SIZE_BIGGEST: 32px;
$FONT_SIZE_BIG: 24px;
$FONT_SIZE_MEDIUM: 20px;
$FONT_SIZE_STANDARD: 16px;
$FONT_SIZE_SMALL: 14px;

$BASE_FONT_SIZE: 16;
$BASE_FONT_SIZE_LARGE: 21;

// Border-box for all yiiha

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;

}

%borderbox {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;

}

%remove-box-shadow {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

}

%clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}

%center {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

%float100 {
  float: left;
  width: 100%;
}

@mixin setFlexInner($dir:column) {
    @include flexbox();
    @include flex-wrap(wrap);
    @include flex-direction($dir);
    @include flex(1, 1, auto);
}

@mixin fontsize($size) {
  font-size: calc($size / $BASE_FONT_SIZE) + em;
}

@mixin resetFontsizeToBase() {
  font-size: calc($BASE_FONT_SIZE / $BASE_FONT_SIZE_LARGE) + em;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacityIE: $opacity * 100;
  filter: alpha(opacity=$opacityIE);
}

@mixin absCenter($width, $height) {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  $centerTop: calc(-1 * $height / 2);
  $centerWidth:  calc(-1 * $width / 2);

  margin: $centerTop 0 0 $centerWidth;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin box-shadow-plain($shad) {
  -webkit-box-shadow: $shad;
  -moz-box-shadow: $shad;
  box-shadow: $shad;
}

@mixin remove-shadow() {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

@mixin rotate($val) {
  -moz-transform: rotate($val);
  -ms-transform: rotate($val);
  -o-transform: rotate($val);
  -webkit-transform: rotate($val);
  transform: rotate($val);

}

@mixin arrow-top($col, $size) {

  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-bottom-color: $col;
  border-width: $size;
  margin-left: -$size;
}

@mixin arrow-right($col, $size) {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-left-color: $col;
  border-width: $size;
  margin-top: -$size;

}

@mixin setPrevIcon($icon,$size:1em,$margin:calc($boxPadding / 2)) {
  &:before {
    @extend %fontIcon;
    content: $icon;
    font-size: $size;
    margin-right: $margin;
    text-decoration: none;
    vertical-align: middle;
    display: inline;

  }
  &:focus {
    &:before {
      text-decoration: none;
    }

  }
}

@mixin setAfterIcon($icon,$size:1em,$margin:calc($boxPadding / 2)) {
  &:after {
    @extend %fontIcon;
    content: $icon;
    font-size: $size;
    margin-left: $margin;
    text-decoration: none !important;
    vertical-align: middle;
    display: inline;

  }
  &:focus {
    &:after {
      text-decoration: none;
    }

  }

}

@mixin transition($param: all, $dur: 0.2s, $ease: ease-in-out) {
  -webkit-transition: $param $dur $ease;
  -moz-transition: $param $dur $ease;
  -o-transition: $param $dur $ease;
  transition: $param $dur $ease
}

@mixin translate($val, $val2) {
  -ms-transform: translate($val, $val2); /* IE 9 */
  -webkit-transform: translate($val, $val2); /* Chrome, Safari, Opera */
  transform: translate($val, $val2);
}

@mixin scale($val) {
  -ms-transform: scale($val); /* IE 9 */
  -webkit-transform: scale($val); /* Chrome, Safari, Opera */
  transform: scale($val);
}

@mixin fontIcon($val) {
  position: absolute;
  @extend %fontIcon;
  content: $val;
}

@mixin gradient($from, $to) {
  /* fallback/image non-cover color */
  background-color: $from;

  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient($from, $to);

  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));

  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient($from, $to);

  /* Opera 11.10+ */
  background-image: -o-linear-gradient($from, $to);

  /* Firefox 3.6+ */
  background-image: linear-gradient($from, $to);

}

@mixin multigradient($fallback, $value) {
  /* fallback/image non-cover color */
  background-color: $fallback;

  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient($value);

  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient($value);

  /* Opera 11.10+ */
  background-image: -o-linear-gradient($value);

  /* Firefox 3.6+ */
  background-image: linear-gradient($value);

}

// Baseline, measured in pixels
// The value should be the same as the font-size value for the html element
// If the html element's font-size is set to 62.5% (of the browser's default font-size of 16px),
// then the variable below would be 10px.
$baseline-px: 16px;

@mixin rem($property, $px-values) {
  // Convert the baseline into rems
  $baseline-rem: $baseline-px / 1rem * 1;
  // Print the first line in pixel values
  #{$property}: $px-values;
  // If there is only one (numeric) value, return the property/value line for it.
  @if type-of($px-values) == "number" {
    #{$property}: $px-values / $baseline-rem;
  } @else {
    // Create an empty list that we can dump values into
    $rem-values: ();
    @each $value in $px-values {
      // If the value is zero or not a number, return it
      @if $value == 0 or type-of( $value ) != "number" {
        $rem-values: append($rem-values, $value);
      } @else {
        $rem-values: append($rem-values, $value / $baseline-rem);
      }
    }
    // Return the property and its list of converted values
    #{$property}: $rem-values;
  }
}

@mixin unFloatBlock($val) {
  width: $val;
  float: none;
  margin-left: auto;
  margin-right: auto;
}

@mixin colorMe($valHex, $valRGB) {
  background-color: $valHex;
  background-color: $valRGB;

}

%setFlexContainer {
    @include flexbox();
    @include align-content(flex-start);
    @include flex-wrap(wrap);
}

%setFlexInner {
    @include flexbox();
    @include align-content(flex-start);
    @include flex-wrap(wrap);
    @include flex-direction(column);
    @include flex(1, 1, auto);
}

%setFlex100Percent {
    @include flexbox();
    @include flex(1, 1, auto);
    @include flex-wrap(nowrap);
}


$sectionMarginLarge: 12px;
$sectionMarginSmall: 8px;
$boxPadding: 8px;
$boxPaddingSmall: 6px;
$boxPaddingLarge: 11px;

$boxMarginBottom: 24px;
$contentItemMarginBottom: 16px;
$contentItemMarginBottomLarge: 44px;

$wrapperXL: 1480px;
$wrapperL: 1008px;
$wrapperM: 762px;
$wrapperS: 480px;
$wrapperXS: 320px;

$sectionSideRatio: calc(282px / $wrapperL);
$sectionSideTeaserRatio: calc(258px / $wrapperL);

$GLOBAL_SMALL_WIDTH_S: ($wrapperS)-($sectionMarginSmall*2);
$GLOBAL_SMALL_WIDTH_XS: ($wrapperXS)-($sectionMarginSmall*2);
$fontDark: #005c63;
$fontColor: #005c63;
$fontHighColor: #fff;
$lightBgColor: #C9DEF1;
$shortInfosColor: #cbecee;
$yellowColor: #FFE918;
$bgColorBrightBlue: #17c0ff;
$sectionTitleColor: #4f4f4f;
$sectionBg: #f7f7f7;
$greyStandard: #ebebeb;
$greyInput: #d8dadb;

$markColor: #ffea19;
$errorColor: #d52e46;
$validColor: #9ad900;
$ipgColor: #e21e8d;
$commentBoxBg: #fef189;

$fontColorYellow: #ffea19;

$ultraMag: ultramagnetic, arial, roboto, "droid sans", helvetica, sans-serif;

@mixin setWebFont($font) {
  h1, h2, h3, h4, h5, #bigstage,
  caption, button, .subnavi ul li a, .moreBtn, .searchForm input, .teaser .dachzeile, p.more, .mediaInfo,
  .subtitle, .broadcastInfo, .controls, .downloadInfo, .searchBox, .icon-duration, .bundleNavi, .mediaHint, .formTrigger,
  .sectionIPGControl, .sectionIPGBody, .conIPGSlider, .closeBtn, p.dachzeile, span.title,
  .modFormVoting label, #header .menueToggle, .footerWrapperKids, .comments_tags {
    font-family: "Mikado", "Helvetica Neue", "Lucida Grande", sans-serif;;
  }
}

@mixin hoverEffect($col1, $col2, $colText) {
  @include gradient($col1, $col2);
  color: $colText;
  a, span:not(.textHighlight), p, div,h4 {
    color: $colText;
  }
}

@mixin hideStageOverflow($height) {
  .modStage .box {
    .teaser, .mediaCon .media {
      max-height: $height;

    }
  }
}

@mixin removeHeadlineButton($colFont, $colBg) {
  .mediaCon {
    margin-bottom: 8px;
  }
  .shortInfos {
    background: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-top: 0;

    .wholeTitle {
      width: 100%;
    }
    p, a, h2, h3, h4, span {
      color: $colFont;
    }
  }
  .mediaCon {
    .mediaInfo {
      background: $colBg;
      .icon-font, .wholeTitle, .wholeTitle .mediaTitle, .info, .mediaTitle {
        color: $colFont;
      }
    }
  }

  .shortInfos .icon-font, .mediaInfo .moreBtn {
    display: none;
  }

}

@mixin activeStageTabbed($col) {
  background-color: $col;
  img {
    opacity: .1;
  }
}

@mixin resetHeadlineContainer($colFont, $colBg) {
  &:not(.modStage) {
    .box:not(.boxMiniKika) {
      .teaser.teaserPreTeen, .infokasten .teaser, .teaser.teaserSearch, .teaser.teaserBreiti, .teaser.teaserInfobox {
        @include removeHeadlineButton($colFont, $colBg);
        &.teaserInfobox {
          .mediaInfo {
            display: none;
          }
        }
      }
    }
  }

}

@mixin hoverEffectOnItems($col1, $col2, $text) {

  %hoverEffectOne {
    @include hoverEffect($col1, $col2, $text);
  }

  .no-touch {
    .mod:not(.modIdentSlider) {

      &:not(.modStage) {
        &:not(.modGallery) {

          .teaser.teaserStandard:hover, .av-playerContainer .teaser.teaserAv:hover, .teaser.teaserIPGLivestream:hover, .media:hover {
            &:not(.teaserInfobox) {
              > .shortInfos, > .mediaInfo {
                @extend %hoverEffectOne;
              }
            }
          }
          .box.galleryLink {
            .teaser.teaserStandard:hover {
              @extend %hoverEffectOne;
            }
          }

          .mediaCon:not(.active) {
            .mediaList {
              .media:hover {
                .mediaInfo, .wholeTitle, .icon-font {
                  @extend %hoverEffectOne;
                }
              }
            }
            &.mediaConAudioButton {
              .mediaList {
                .media:hover {
                  .mediaInfo {
                    background: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .mod {
      .jumpLabelList, .teaser .linklist {
        ul li a {
          &:hover {
            @extend %hoverEffectOne;
          }
        }
      }
      .controls.list .entry a:hover {
        @extend %hoverEffectOne;
      }
    }
  }

}


%standardFont {
  font-family: "Helvetica Neue", Helvetica, Arial, Segoe, "Segoe UI", Roboto, Tahoma, sans-serif;
}


%trenner-bottom {
  border-bottom: 4px solid $greyStandard;
}

%trenner-top {
  border-top: 4px solid $greyStandard;
}

%box-background {
  background-color: $sectionBg;
  @include border-radius(3px);
  @include box-shadow(0, 0, 4px, rgba(0, 0, 0, .6));

}

%box-background-hover {
  background-color: #ffffff;
  @include box-shadow(0, 0, 12px, rgba(0, 0, 0, 1));
}

%no-background {
  background-color: transparent;
  @include border-radius(0);
  @include box-shadow(0, 0, 0, rgba(0, 0, 0, .6));
}

%blueGradient {
  @include gradient(#d6d6d6, $bgColorBrightBlue);
}

%greyGradient {
  @include gradient(#f1f1f1, #d6d6d6);
}

%blueHoverEffect {
  @include hoverEffect($bgColorBrightBlue, $bgColorBrightBlue, #fff);
}

%removeHoverEffect {
  background: none !important;

  h3, a, span:not(.textHighlight), div {
    color: inherit;

  }
}

%standardBg {
  @include multigradient(#54b5c6, (#54b5c6 0px, #65dff3 300px, #65dbf0 310px, #6ae6fa 320px, #6be7fd));

}

%fontIcon {
  font-family: 'kikons';
}


@import "../../../sass/core";
@import "../../../sass/kika-variables";

// Fullscreen
%fullscreenFixed {
	background-color: #000;
	width: 100% !important;
	height: 100% !important;
	position: absolute !important;
	top: 0;
	left: 0;
}

$fontColor: #005c63;
$fontHighColor: #fff;
$lightBgColor: #c9def1;
$shortInfosColor: #cbecee;
$yellowColor: #ffe918;
$bgColorBrightBlue: #17c0ff;
$sectionBg: #f7f7f7;
$greyStandard: #ebebeb;
$kikaYellow: #ffea19;

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow: inset $top $left $blur $color;
		-moz-box-shadow: inset $top $left $blur $color;
		box-shadow: inset $top $left $blur $color;
	} @else {
		-webkit-box-shadow: $top $left $blur $color;
		-moz-box-shadow: $top $left $blur $color;
		box-shadow: $top $left $blur $color;
	}
}

%btn-shadow {
	@include box-shadow(0px, 0px, 6px, rgba(0, 0, 0, 1));
}

%btn-shadow-left {
	@include box-shadow(6px, 0px, 6px, rgba(0, 0, 0, 1));
}

@keyframes blendIn {
	0% {
			opacity:0;
	}
	100% {
			opacity:1;
	}
}
@keyframes blendOut {
	0% {
		opacity:1;
	}
	100% {
		opacity:0;
	}
}

@mixin hasRelated {
	.avPlayerLayer.avLayerEndscreen.hasRelated{
		@content;
	}
}

@mixin iconFont ($value) {
	.moreBtn a .icon-font {
		margin-right: $value !important;
	}
}

@mixin iconFontContent {
	.moreBtn a .icon-font {
		@content
	}
}

@mixin mediaConRelatedVideos ($value) {
	.mediaConRelatedVideos {
		min-width: $value !important;
		@content;
	}
}

.av-playerContainer {
	font-size: 16px;

	* {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-ms-box-sizing: border-box;
		box-sizing: border-box;
	}



	&.kikaAv-small .avLayerEndscreen,
	&.kikaAv-smallest .avLayerEndscreen,
	.avLayerEndscreen.avLayerEndscreenSmall {
		h3.headline,
		.mediaConRelatedVideos,
		.controls.sliding {
			display: none !important;
		}
	}



	&.kikaAv-big {
		&.avPlayer {
			&StandardSize {
				@include hasRelated {
					@include mediaConRelatedVideos(70%){
						overflow-x: hidden;
					}
					@include iconFontContent {
						.view-xl & {
							margin-right: -0.122em !important;
						}
						.view-m &, .view-l & {
							margin-right: -0.1em !important;
						}

					}
					&.fullSizePlayerSmallViewport{
						@include iconFontContent {
							.view-m &, .view-l & {
								margin-right: -0.122em  !important;
							}
						}
					}
				}
			}
			&BigSize {
				@include hasRelated {
					@include mediaConRelatedVideos(60%);
					@include iconFont(-0.1em);
					&.fullSizePlayer {
						@include mediaConRelatedVideos(50%);
						@include iconFont(0.1em);
					}
				}
			}
		}
	}
	&.avPlayerBigSize {
		@include hasRelated {
			.mediaConRelatedVideos {
				.modLightbox-contentWrapper & {
					min-width: 50% !important;
				}
			}
		}
	}

	position: relative;
	overflow: hidden;
	color: #fff;
	background-color: #000;
	width: 100%;
	display: block;
	z-index: 3;

	&.streamer {
		.avPlayerLayer.avPlayerInfoLayer {
			display:none !important;
		}
	}

	.fskMessageWrapper {
		position: absolute;
		text-align: left;
		top: 0;
		left: 0;
		color: #fff;
		width: 100%;
		height: 100%;
		z-index: 4;
	}

	.brav-equalizer {
		position: absolute;
		left: 8px;
		width: 80px;
		height: 80px;
		background: $bgColorBrightBlue url(/resources/img/kika/equalizer_green.gif) no-repeat center center;
		z-index: 2;
		bottom: 46px;
		background-color: $bgColorBrightBlue;
		background-size: 80% 80%;
		@include border-radius(3px);
		display: none;
		transition: all 0.3s ease-out;
	}

	&.noEmbedPlay.pause video {
		-webkit-transform: translateX(-2048px);
	}
	span.previewHidden {
		display: none !important;
	}
	&.hasAudio .controls .avPlayerAdd .controls-left {
		width:100%;
		.btn.rating {
			float: right;
		}
		.btn.infoBtn {
			display: none;
		}
	}
	&:not(.hasAudio) {
		img.previewHidden {
			opacity: 0;
		}
	}
	img {
		width: 100%;
		height: auto;
		float: none !important;
	}
	.teaserImage {
		position: relative;
		width: 100%;
		margin: 0px;
		padding: 0px;
		height: auto;
	}
	video,
	audio {
		height: auto !important;
		float: left;
	}

	.inv {
		position: absolute;
		left: -999em;
		top: -999em;
		display: none;
	}

	a {
		display: block;
		float: left;
		width: 100%;
	}

	&.html5 {
		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0 !important;
		}
	}

	.buffer {
		overflow: hidden;
		position: absolute;
		width: 60px;
		height: 50px;
		top: 50%;
		margin-top: -30px;
		left: 50%;
		margin-left: -30px;
		z-index: 1;
	}
	&.dragging {
		.controls {
			.avPlayerCurrentTimeInfo {
				display: block;
			}
		}
	}

	.controls {
		transition: opacity 0.3s ease-out;

		width: 100%;
		padding: 8px;
		/*height: 32px;*/
		position: absolute;
		bottom: 0;
		z-index: 3 !important;
		/*background: none !important;
		background-color: transparent !important;*/
		.avPlayerCurrentTimeInfo {
			position: absolute;
			bottom:40px;
			color: $fontColor;
			background-color: #fff;
			padding: 4px;
			min-width: 44px;
			margin-left: -22px;
			display: none;
			span {
				display: block;
				text-align: center;

				&.time-right {
					display: none;
				}
			}
			line-height: 1em;
			z-index: 1;
			@include box-shadow(0, 6px, 10px, rgba(0, 0, 0, 0.6));

			&:after {
				top: 100%;
				left: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(0, 0, 0, 0);
				border-top-color: #fff;
				border-width: 5px;
				margin-left: -5px;
			}
		}

		.avPlayerProgressBarWrapper {
			position: static;
			.avPlayerProgressBar {
				overflow: hidden;
				@include border-radius(4px);
				cursor: pointer;
				position: relative;
				bottom: 0;
				width: 100%;
				margin: 0;
				height: 26px;
				display: block;
				opacity: 0.8;
				background-color: rgba(230, 230, 230, 1);

				.avPlayerProgress,
				.avPlayerProgressTotal {
					height: 26px;
					z-index: 2;
					position: absolute;
				}

				.avPlayerProgressHandle {
					display: block;
					position: absolute;
					width: 4px;
					height: 22px;
					background-color: #128cb2;
					z-index: 3;
					/* border-left: 3px solid #CCD; */
					margin-left: -10px;
					top: 2px;
					@include border-radius(2px);

					&:before,
					&:after {
						position: absolute;
						content: "";
						width: 4px;
						height: 22px;
						background-color: #128cb2;
						@include border-radius(2px);
					}
					&:before {
						right: 7px;
					}

					&:after {
						right: 14px;
					}
				}

				.avPlayerProgress {
					background-color: rgba(23, 192, 255, 1);
				}
				.avPlayerProgressTotal {
					z-index: 1;
				}
			}
		}

		.timeFrame {
			display: inline-block;
			float: left;
			color: $fontColor;
			bottom: 3px;
			z-index: 5;
			left: 0;
			padding: 10px 16px 9px 16px;
			position: absolute;
			line-height: 1em;
			width: 100%;

			.time-right {
				float: right;
			}
		}

		.avPlayerAdd {
			float: right;
			position: absolute;
			right: 0;
			bottom: 44px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			transition: opacity 0.3s ease-out;
		}
		.avPlayerAdd .controls-left {
			margin-right: auto;
			margin-left: 8px;
		}


	}

	.btn {
		position: relative;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content:center;
		float: left;
		top: 0;
		text-align: center;
		background-color: #fff;
		@include border-radius(50%);
		margin-right: 8px;
		cursor: pointer;
		span {
			margin:0;
			color: $fontColor;
			&.btn-icon {
				display: block;
				font-size: 20px;
				color: $fontColor;
				float: left;
				width: 100%;
			}
		}
		&.activated {
			span {
				color: $bgColorBrightBlue;
			}
		}

		.innerBtn {
			position: relative;
			width: 46px;
			height: 46px;
		}

		&.fullscreenBtn {
			.btn-icon-fullleave {
				display: none;
			}
		}
		&.liveBtn {
			span.btn-icon {
				background: none;
				font-size: 16px;
				font-weight: bold;
				left: 16px;
				top: 23px;
			}
		}
		&.avDownloadBtn {
			width: auto;
			border-radius: 0.25rem;
			padding: 0 1rem;
			text-transform: uppercase;
			font-size: 1.125rem;
			position: absolute;
			left: 50%;
			transform: translate3d(-50%,0,0);
			display: flex;
			align-items: center;
			justify-content:center;

			&.singleView {
				bottom:0.5rem;
				z-index: 10;
				top:auto;
				background-color: rgba(255,255,255,.75);

			}
			.brav-ready & {
				display:none;
			}
		}

		&.rating {
			@include border-radius(8px);
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&.biggerBtn {
			span.btn-icon {
			}
			&.bigger {
				.span.btn-icon {
				}
			}
		}

		&.popoutBtn {
			span.btn-icon {
			}
		}
		&.avPlayerBtnSubtitle {
			span.btn-icon {
			}
		}

		&.avPlayerBtnPlayPause {
			display: none !important;
			span.btn-icon {
				margin-left: -8px;
			}
		}
		&.avPlayerBtnVolume {
			span.btn-icon {
			}
			&.muted {
				span.btn-icon {
					color: rgba(0, 0, 0, 0);

					&:before {
						content: "\e01e";
						position: absolute;
						left: 50%;
						margin-left: -10px;
						color: $fontColor;
					}
				}
			}
		}
	}

	.icon-duration {
		position: absolute;
		bottom: 8px;
		right: 8px;
		z-index: 2;
		line-height: 1em;
		padding: 3px;
		color: #fff;
		background-color: $bgColorBrightBlue;
		@include border-radius(3px);
	}

	.icon-font.play-icon {
		position: absolute;
		z-index: 2;
		left: 8px;
		bottom: 8px;
		font-size: 3rem;
		display: flex;
		text-align: left;
		margin: 0;
		color: $kikaYellow;
	}
	&.init {
		.icon-font.play-icon {
			font-size: 8rem;
			.modBundleGroupNavi & ,.modMini &{
				font-size: 4rem;
			}
			bottom: auto;
			top: 0;
			left: 0;
			margin: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-content: center;
			pointer-events: none;
			justify-content: center;
			align-items: center;
			transform-origin: 50% 50% 0 !important;
			flex-direction:column;
		}
	}

	.playerHolder {
		display: block;
		width: 100%;
		height: 100%;
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;


		.icon-duration {
			display: none;
		}
	}

	// States
	&.playing {
		.icon-font.play-icon {
			color: rgba(0, 0, 0, 0);
			&:before {
				content: "\f03d";
				color: $kikaYellow;
				left: 0;
				position: absolute;
				text-align: center;
				width: 100%;
			}
		}
		.brav-equalizer {
			display: block;
		}
	}

	&.pause {
		.controls {
			.btn.avPlayerBtnPlayPause {
				span.btn-icon {
					background-position: -40px -90px;
					margin-left: -8px;
				}
			}
		}
	}

	&.ready {
		.controls {
			display: block;
			/* background: none !important; please leave it commented out, otherwise the background image for the linear shadow will disappear */
		}
	}

	&.ended {
		> video.inv {
			display: block !important;
			left: auto !important;
			top: auto !important;
		}
		.play-icon {
			display: none !important;
		}

		.controls {
			z-index: unset!important;
			.btn.avPlayerBtnPlayPause {
				span.btn-icon {
					background-position: -260px -90px;
					margin-left: -12px;
					margin-top: -16px;
					height: 30px;
					width: 24px;
				}
			}
		}
		.icon,
		.rating {
			display: block;
			z-index: 6;
		}
	}

	&.nativeControls {
		height: auto;

		.avPlayerBtnPlayPause,
		.avPlayerProgressBar,
		.avPlayerVolume,
		.fullscreenBtn {
			display: none !important;
		}
		.controls {
			position: relative;
			display: none !important;
		}
	}

	.avPlayerSubtitles {
		position: absolute;
		bottom: 0;
		z-index: 1;
		width: 100%;
		font-size: 16px;
		line-height: 1.121;
		background-color: rgba(0, 0, 0, 0.8);
		color: #fff;

		&.empty {
			padding-bottom: 0;
		}
		p {
			display: block;
			padding: 20px;
			margin: 0 auto;
			position: relative;
			text-align: center;
		}

		&.left p {
			text-align: left;
		}
		&.right p {
			text-align: right;
		}
		&.center p {
			text-align: center;
		}
		&.empty p {
			display: none;
		}
	}

	.avPlayerLayer {
		top: 0;
		left: 0;
		position: absolute;
		z-index: 5;

		.teaser {
			.shortInfos {
				border-bottom: 0;
			}
		}

		.unitD,
		.unitY {
			width: 100%;
			text-align: left;
		}

		.controls.sliding {
			border: 0 !important;
			height: 0 !important;
			z-index: 0 !important;
			position: static;
			.buttons {
				position: static;
			}

			a.prev,
			a.next {
				cursor: pointer;
				background-color: $sectionBg;
				width: 32px !important;
				height: 44px !important;
				margin-right: 0;
				min-width: 0;
				margin-left: 0;
				&:before {
					margin-top: -7px;
					left: 50%;
				}
				z-index: 2;
				top: 50%;
				margin-top: -22px;
			}
			a.prev {
				left: 0 !important;
				@include border-radius(0 100px 100px 0);
				&:before {
					margin-left: -14px;
				}
			}
			a.next {
				@include border-radius(100px 0 0 100px);
				right: 0 !important;
				&:before {
					margin-left: -5px;
				}
			}
			.owl-dots {
				display: none;
			}
		}

		&.avLayerEndscreen {
			&.error {
			}
			.controls {
				display: none;
			}

			&:before {
				position: absolute;
				content: " ";
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.6);
			}
			&.hasRelated {
				.moreBtn {
					max-width: 50%;
				}
				.ui-pieProgress {
					.ui-pieProgress-pause {
						display: block !important;
						color: #ffe918 !important;
					}
					.ui-pieProgress-start{
						display: none !important;
					}
					&.paused {
							.ui-pieProgress{
							&-pause{
								display: none !important;
							}

							&-start{
								display: block !important;
							}
						}
					}
					&.paused, &.start {
						z-index: 12 !important;
					}

				}
				.mediaConRelatedVideos {
					.mediaCon {
						.autoplayCounter {
							z-index: 10;
						}
					}
				}
				.shortInfos {
					overflow: hidden;
					text-overflow: ellipsis;
					width: 60%;
					.wholeTitle {
						.headline a {
							white-space: nowrap;
						}
					}
				}
				.mediaCon {
					width: 40%;
				}
			}
			.moreBtn {
				position: relative;

				margin: 0 !important;
				font-size: 4em;
				right: auto;
				cursor: pointer;
				width: 100%;
				background-color: transparent;
				line-height: 1.21em !important;

				.linktext {
					display: none;
				}
				html.view-xs &,
				html.view-s & {
					width: 100%;
				}

				html.no-touch & {
					&:hover {
						background-color: transparent !important;
						span {
							color: $yellowColor !important;
						}
					}
				}
				a {
					color: $yellowColor;
				}
			}
			.linkAll {
				position: relative;
				z-index: 4;
			}

			h3.headline {
				font-size: 1.18em;
				padding: 12px;
				position: relative;
				background-color: $sectionBg;
				min-width: 60%;
				float: right;
				max-width: 640px;

				margin-top: 22px;
				@include border-radius(32px 0 0 32px);
				@include box-shadow-plain(0 2px 2px rgba(0, 0, 0, 0.5));
				z-index: 13;
				html.view-xs &,
				html.view-s & {
					display: none;
				}
			}
			.avPlayerLayer-relatedWrapper {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}

			.mediaConRelatedVideos {
				padding: 6px;
				padding-bottom: 12px;
				position: relative;
				max-width: 500px;
				top: 0;
				padding-top: 80px;
				min-width: 60%;
				height: 100%;
				right: 0;
				overflow-y: scroll;
				overflow-x: hidden;
				flex: 1 1 auto !important;
				.mediaHint {
					display: none;
				}
				html.view-xs &,
				html.view-s & {
					display: none;
				}
				.boxCon {
					flex-wrap: wrap !important;
				}

				.box {
					padding-left: 8px;
					transition: transform .3s ease-out;

					html.view-m & {
						font-size: 0.875em;
					}

					.mediaCon .media:before {
						position: absolute;
						width: 100%;
						height: 100%;
						content: "";
						background-color: $bgColorBrightBlue;
						top: 0;
						left: 0;
						z-index: 4;
						opacity: 0.2;
						display: none;
					}

					&.active {
						padding-left: 0 !important;
						.shortInfos {
							background-color: $bgColorBrightBlue;
							> *,
							a {
								color: #fff !important;
							}
						}
						.mediaCon .media:before {
							display: block;
						}
					}

					html.no-touch & {
						&:hover {
							transform: scale(1.05);
							.mediaCon .media:before {
								display: block;
							}
						}
					}
				}

				.owl-stage {
					@extend %setFlexContainer;
				}
				.owl-item {
					@extend %setFlexInner;
				}
				.shortInfos {
					background: none;
					background-color: $sectionBg;

					p,
					h4 {
						a {
							color: $fontColor;
						}
					}
				}
				.mediaCon,
				.shortInfos {
					position: relative;
					width: 60%;
					height: auto !important;

					.icon-font {
						display: none;
					}
				}
				.shortInfos {
					width: 40%;
				}
				.shortInfos:before,
				.mediaCon:before {
					position: relative;
					overflow: hidden;
				}
				.shortInfos {
					> span {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						padding: 8px !important;
						p,
						h4 {
							padding: 0 !important;
						}
						h4 {
							html.view-m & {
								font-size: 1em;
								font-weight: bold;
							}
						}
					}
				}
				.mediaCon {
					.av-playerContainer {
						height: 100%;
					}
					.ui-pieProgress {
						left: 4px;
						bottom: 40px;
						top: auto;
						.icon-font.ui-pieProgress-start {
							display: block;
							color: $yellowColor;
							font-size: 1.3em;
							margin-left: -9px;
							margin-top: 10px;
						}
						path {
							stroke: $yellowColor !important;
						}
					}
					.autoplayCounter {
						position: absolute;
						right: 0;
						top: 0;
						z-index: 12;
						width: 100%;
						font-weight: bold;
						text-align: right;
						color: $yellowColor;
						display: block;
						padding: 8px;
						font-size: 0.9rem;
						html.view-m & {
							line-height: 1;
						}
					}

					.media {
						overflow: hidden;
						img {
							@include border-radius(0);
						}
						@include border-radius(0 3px 3px 0 !important);
					}
				}
				.shortInfos {
					@include border-radius(3px 0 0 3px !important);
				}

				.box {
					padding-top: 0 !important;
					padding-bottom: 0 !important;

					@extend %setFlexInner;
				}
				.teaser {
					@extend %setFlexInner;

					/**
          @todo: mixin!
          */

					-webkit-flex-direction: row-reverse !important;
					-ms-flex-direction: row-reverse !important;
					flex-direction: row-reverse !important;
					.teasertext,
					ul.linklist {
						display: none;
					}
				}

				.av-playerContainer {
					background-color: transparent;
					.icon-font {
						font-size: 36px;
						position: absolute;
						top: 26px;
						left: 16px;
						right: auto;
						text-align: left;
						width: 36px;
					}
				}
			}

			line-height: 1.21em;
			width: 100%;
			height: 100%;

			.avLayerEndMessage {
				font-weight: bold;
				font-size: 14px;
				display: table-cell;
				vertical-align: middle;
				text-align: center;
				position: relative;
				color: #fff;
				float: left;
				width: 100% !important;
				margin: 0 !important;
				p {
					color: #fff !important;
					margin-top: 32px;
				}
			}
		}

		&.avPlayerInfoLayer {
			position: static;
			left: 0;
			top: 0;
			padding: 0;
			width:100%;
			height:100%;

			.infoBtn {
				position: absolute;
				top: calc(100% - 84px);
				left: 8px;
				z-index: 3;
			}
			label {
				width: 100%;
				float: left;
				margin-bottom: 12px;
			}
			.innerLayer {
				position: absolute;
				z-index: 2;
				left: 0;
				top: 0;
				line-height: 1.2em;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, .8);
				overflow-y: scroll;
				justify-content: center;
				align-items: center;
				display: flex;

				.innerLayerWrapper {
					max-width: 520px;
				}

				.close-btn {
					@extend %btn-shadow;
					cursor: pointer;
					&:before {
						position: absolute;
						content: "";
						width: 32px;
						height: 40px;
						background-color: #fff;
						left: -12px;
						top: 0;
					}
					&:after {
						position: absolute;
						content: "\e031";
						width: 32px;
						height: 32px;
						color: $fontColor;
						@extend %fontIcon;
						left: 12px;
						top: 11px;
						font-size: 21px;
					}
					position: absolute;
					width: 40px;
					height: 40px;
					right: -36px;
					top: 0;
					z-index: 1;
					background-color: #fff;
					@include border-radius(3px);

					.icon-font {
						color: $fontColor;
					}
				}
				.avPlayer-screen {
					.shortInfos {
						background: none;
						padding: 0;
					}
					h3 {
						margin-bottom: 12px;
					}
					hr.divider {
						height: 2px;
						width: 100%;
					}
					h3,
					h1,
					p,
					hr {
						color: #fff !important;
						float: left;
						width: 100% !important;
					}
					h1 {
						font-size: 1.5rem;
					}
					p.subtitle {
						font-size: 1.125rem;
					}
				}
			}
		}

		&.avPlayerCornerBug {
			display:flex;
			pointer-events:none;
			align-items: center;
			justify-content:center;
			width:100%;
			img,p {
				opacity: 0;
				animation:blendIn .4s ease-out;
				animation-fill-mode: forwards;
			}
			p {
				animation-delay: .3s;
			}
			img.avPlayer-corner-3 {
				animation-delay: .6s;
			}

			&.avplayer-state-out {
				img,p {
					opacity: 1;
					animation-name: blendOut;
					animation-fill-mode: forwards;
				}

			}
			img {
				width:20%;
				position: relative;
				top:auto;
				left:auto;
			}
			p {
				color: #fff;
				font-size: 1.5rem;
				font-weight: bold;
				width: 65px;
				text-align: center;
			}
		}
	}

	/* teaser endscreen */
	.teaserBundle {
		max-width: 512px;
		margin: 0 auto;
		article {
			float: left;
			width: 48%;
			padding: 12px;
			background-color: #ebebeb;
			margin-right: 2%;
		}
	}

	// Sizes


	&.kikaAv-small {
		
		.btn.avDownloadBtn.singleView {
			top: 1rem;
			transform: translate3d(-50%,0, 0);
		}
		.brav-equalizer {
			width: 60px;
			height: 60px;
		}
		.avPlayerAdd .controls-left {
			margin-right: 0;

		}
		.avPlayerAdd .btn.avDownloadBtn {
			bottom:0;
			transform: translate3d(0,0,0);
			left:0;
			position: relative;
			width:2.5rem;
			padding:0;
			border-radius: 50%;
			margin:0 auto 0 0;
			span {
				margin:0;
			}
			span:not(.icon-font) {
				display:none;
			}
		}
	}
	&.kikaAv-smallest {
		.avPlayerInfoLayer {
			display: none;
		}
	}
}

.brav-showControls {
	.avPlayerSubtitles {
		padding-bottom: 40px;
	}
	.playerHolder:before {
			position: absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:1;
			content:'';
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
	}
}
.brav-hideControls {
	.controls,
	.play-icon,
	.avPlayerAdd,
	.btn.rating {
		opacity: 0 !important;
	}
	.brav-equalizer {
		bottom: 8px;
	}
}

.brav-ready {
	.mediaHint {
		pointer-events: none;
		display: none !important;
	}
}
.brav-buffer {
	.play-icon {
		display:none !important;
	}
}
.av-playerContainer {
	button.btn.active {
		span {
			color: $bgColorBrightBlue;
		}
	}
}

.no-touch {
	.av-playerContainer {
		button.btn:hover {
			span {
				color: $bgColorBrightBlue;
			}
		}
	}
}

// Fullscreen

html.brav-fullscreen:not(.onepager) {
	.av-playerContainer {
		@extend %fullscreenFixed;

		.playerHolder,
		> span:not(.play-icon),
		video,
		.linkClicker {
			@extend %fullscreenFixed;
		}
		span.play-icon,
		.playerHolder,
		.avPlayerSubtitles {
			z-index: 100 !important;
		}
		.playerHolder {
			opacity: 0;
		}
		.avPlayerLayer {
			z-index: 101 !important;
			position: absolute;
		}
		.avPlayerLayer .mediaConRelatedVideos .av-playerContainer {
			position: relative !important;
		}
		video {
			z-index: 99 !important;
		}
		.controls {
			width: 100%;
			position: absolute !important;
			z-index: 102 !important;
			left: 0;
		}
		.btn.rating {
			z-index: 101 !important;
		}
		.linkClicker {
			z-index: 101 !important;
		}
		.avPlayerSubtitles {
			bottom: 32px;
		}
		.avPlayerSubtitles p {
			font-size: 1.2em;
		}
		.btn.fullscreenBtn {
			.btn-icon-fullleave {
				display: block;
			}
			.btn-icon-fullenter {
				display: none;
			}
		}
	}
}
html.embed-player .av-playerContainer .icon-font.play-icon {
  font-size: 3rem !important;
}

// Viewports

@include hasRelated {
	.box {
		.view-xl & {
			width: 100% !important;
			max-width: 100% !important;
		}
	}
}



